import { valueToParam } from '../helpers/search';
import { MaybeCallback } from '../types/common';

interface UseSelectableLocationOptions {
  query: 'properties' | 'projects';
  navigateToLocations: boolean;
  buyType: 'RES' | 'RNT' | 'NEW';
  propertyType: 'house' | 'apartment';
  priceQuery: string;
  beforeNavigate?: () => void;
}

interface Location {
  text?: string;
  value: string;
  type: string;
  id?: string;
  itemId?: string;
  city?: string;
  county?: string;
  postal_code?: string;
  address?: string;
  transaction_type?: 'RES' | 'RNT';
  subType?: string;
  status?: 'on-the-market' | 'preconstruction';
  project_name?: string;
}

export default function (
  _options: MaybeCallback<Partial<UseSelectableLocationOptions>> = {}
) {
  const options =
    typeof _options === 'function'
      ? computed<Partial<UseSelectableLocationOptions>>(_options)
      : ref<Partial<UseSelectableLocationOptions>>(_options);

  const router = useRouter();

  const localePath = useLocalePath();

  const { t } = useI18n();

  const { getProjectLink, getUsedProjectLink, getPropertyLink } =
    useProperties();

  const selectedLocation = ref<Location | null>(null);

  const enableRedirect = ref(false);

  const selectedLocationLink = computed(() => {
    const {
      query = 'properties',
      buyType = 'RES',
      propertyType = undefined,
      priceQuery = ''
    } = options.value;

    if (!selectedLocation.value) {
      return '';
    }

    if (
      ((selectedLocation.value.type === 'project' &&
        selectedLocation.value.status === 'on-the-market') ||
        selectedLocation.value.type === 'building') &&
      selectedLocation.value.value
    ) {
      console.log(
        '[SEARCH] Updating selected location (used project)',
        selectedLocation.value
      );
      return localePath(
        `/${t('routes.properties')}/` +
          getUsedProjectLink(
            {
              projectName: selectedLocation.value.value,
              id: selectedLocation.value.itemId.toString(),
              city: selectedLocation.value.city,
              county: selectedLocation.value.county,
              subType: selectedLocation.value.subType,
              address: selectedLocation.value.address
            },
            t
          )
      );
    } else if (
      selectedLocation.value.type === 'project' &&
      selectedLocation.value.itemId
    ) {
      console.log(
        '[SEARCH] Updating selected location (project)',
        selectedLocation.value
      );
      return localePath(
        `/${t('routes.projects')}/` +
          getProjectLink(
            {
              id: selectedLocation.value.itemId.toString(),
              projectName: selectedLocation.value.value,
              county: selectedLocation.value.county,
              city: selectedLocation.value.city,
              address: selectedLocation.value.address
            },
            t
          )
      );
    } else if (
      ['address', 'mls_id'].includes(selectedLocation.value.type) &&
      typeof selectedLocation.value.itemId
    ) {
      console.log(
        '[SEARCH] Updating selected location (address)',
        selectedLocation.value
      );
      return localePath(
        `/${t('routes.properties')}/` +
          getPropertyLink(
            {
              id: selectedLocation.value.itemId,
              full: selectedLocation.value.full,
              county: selectedLocation.value.county,
              city: selectedLocation.value.city,
              type: selectedLocation.value.transaction_type
            },
            t
          )
      );
    } else {
      const params: Record<string, string> = {};

      let includeLocations = false;

      if (selectedLocation.value.type === 'zip_code') {
        params.zipcode = selectedLocation.value.value;
        params.city = valueToParam(selectedLocation.value.city);
        params.county = valueToParam(selectedLocation.value.county);
        delete params.location;
        includeLocations = true;
      } else if (selectedLocation.value.type === 'city') {
        params.city = valueToParam(selectedLocation.value.value);
        params.county = valueToParam(selectedLocation.value.county);
        delete params.location;
        includeLocations = true;
      } else if (selectedLocation.value.type === 'county') {
        params.county = valueToParam(selectedLocation.value.value);
        delete params.location;
        includeLocations = true;
      } else if (selectedLocation.value.type === 'geolocation') {
        params.geolocation = selectedLocation.value.value;
        delete params.location;
      } else if (selectedLocation.value.type === 'neighborhood') {
        params.county = valueToParam(selectedLocation.value.county || '');
        params.city = valueToParam(selectedLocation.value.city || '');
        params.zipcode = selectedLocation.value.postal_code;
        params.neighborhood = valueToParam(selectedLocation.value.value);
        delete params.location;
      }

      if (params.county?.endsWith('-county')) {
        params.county = params.county.slice(0, -7);
      }

      if (params.county) {
        params.city = params.city || '';
        params.zipcode = params.zipcode || '';
      } else if (params.city) {
        params.zipcode = params.zipcode || '';
      }

      params.price = priceQuery;
      params.buyType = buyType;
      params.propertyType = propertyType;

      return localePath(
        `/${t(
          query === 'projects' ? 'routes.projects' : 'routes.properties'
        )}/` +
          [
            'buyType',
            'propertyType',
            'county',
            'city',
            'zipcode',
            'geolocation',
            'neighborhood',
            'location',
            'price'
          ]
            .map(k => {
              // TODO: Part of this code is repeated in pages/index.vue
              let v = params?.[k];
              if (k === 'buyType' && v === 'NEW') {
                return '';
              }
              if (!(k in params)) {
                if (k === 'buyType') {
                  v = 'RES';
                } else if (!['county', 'city', 'zipcode'].includes(k)) {
                  return '';
                }
              }
              if (k === 'buyType' || k === 'propertyType') {
                return v ? valueToParam(v, true, t) : '';
              } else {
                if (
                  !v &&
                  v !== '' &&
                  ['county', 'city', 'zipcode'].includes(k)
                ) {
                  return includeLocations ? t(`paramsKeys.${k}`) : '';
                } else if (v) {
                  return v + '-' + t(`paramsKeys.${k}`);
                }
                return '';
              }
            })
            .filter(Boolean)
            .join('/')
      );
    }
  });

  function navigateToSelectedLocation() {
    console.log('[SEARCH] Selected location changed', selectedLocation.value);

    if (!enableRedirect.value) {
      return;
    }

    const { navigateToLocations = false, beforeNavigate = () => {} } =
      options.value;

    if (
      !navigateToLocations &&
      ['zip_code', 'city', 'county', 'neighborhood', 'geolocation'].includes(
        selectedLocation.value?.type
      )
    ) {
      return;
    }

    const link = selectedLocationLink.value;

    if (link) {
      console.log('[SEARCH] Navigating to selected location', link);
      if (beforeNavigate) {
        beforeNavigate();
      }
      navigateTo(link);
    }
  }

  watch(selectedLocation, () => {
    navigateToSelectedLocation();
  });

  watch(router.currentRoute, () => {
    enableRedirect.value = false;
    setTimeout(() => {
      enableRedirect.value = true;
    }, 1000);
  });

  onMounted(() => {
    enableRedirect.value = false;
    setTimeout(() => {
      enableRedirect.value = true;
    }, 1000);
  });

  return { selectedLocation, selectedLocationLink };
}
